import React from 'react';
import { Box } from '../components/Core';
import { Element } from "react-scroll";
import PageWrapper from '../components/PageWrapper';
import Hero from '../sections/landing4/Hero';
import Works from '../sections/grid-3-fluid/Works';
import CTA from '../sections/landing4/CTA';
import Testimonials from '../components/Testimonials';
import Process from '../components/Process';

const IndexPage = () => {
  return (
    <>
      <PageWrapper darkTheme>
        <Box>
          <Hero />
          <Element name="works">
            <Works />
          </Element>
          <Process />
          <Element name="testimonials">
            <Testimonials />
          </Element>
          <CTA />
        </Box>
      </PageWrapper>
    </>
  );
};

export default IndexPage;
