import React from 'react';
import styled from 'styled-components';

const ProcessSection = styled.section`
  padding: 4rem 0;
  background-color: #111827;
`;

const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  color: white;
  margin-bottom: 3rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const StepContainer = styled.div`
  position: relative;
  padding: 1.5rem;
  padding-left: 3rem;
  display: flex;
  flex-direction: column;
`;

const StepNumber = styled.div`
  position: absolute;
  left: 0;
  top: 1.5rem;
  width: 2rem;
  height: 2rem;
  background-color: #3B82F6;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
`;

const StepTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  color: white;
  margin-bottom: 0.75rem;
  line-height: 1.75rem;
  padding-left: 0;
`;

const StepDescription = styled.p`
  color: #9CA3AF;
  padding-left: 0;
`;

const Process = () => {
  return (
    <ProcessSection>
      <Container>
        <Title>Näin toimimme</Title>
        <Grid>
          <StepContainer>
            <StepNumber>1</StepNumber>
            <StepTitle>Yhteydenotto</StepTitle>
            <StepDescription>
              Ota yhteyttä ja kerro tarpeistasi. Sovimme kartoituskäynnin.
            </StepDescription>
          </StepContainer>
          <StepContainer>
            <StepNumber>2</StepNumber>
            <StepTitle>Suunnittelu</StepTitle>
            <StepDescription>
              Teemme tarkan suunnitelman ja tarjouksen tarpeidesi mukaan.
            </StepDescription>
          </StepContainer>
          <StepContainer>
            <StepNumber>3</StepNumber>
            <StepTitle>Toteutus</StepTitle>
            <StepDescription>
              Toteutamme projektin sovitussa aikataulussa ja budjetissa.
            </StepDescription>
          </StepContainer>
        </Grid>
      </Container>
    </ProcessSection>
  );
};

export default Process; 