import React from 'react';
import styled from 'styled-components';

const StarRating = styled.div`
  color: #ffd700;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
`;

const TestimonialSection = styled.section`
  padding: 4rem 0;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
  color: #ffffff;
`;

const SectionSubtitle = styled.p`
  font-size: 1.25rem;
  color: #9ca3af;
  text-align: center;
  margin-bottom: 3rem;
`;

const TestimonialCard = styled.div`
  background-color: ${({ theme }) => theme.colors.dark};
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  padding: 1.5rem;
  margin-bottom: 2rem;
  transition: transform 0.3s ease;
  border: 1px solid #3d3d3d;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
`;

const CardTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #ffffff;
`;

const CardLocation = styled.p`
  color: #9ca3af;
  margin-bottom: 0;
`;

const CardDate = styled.span`
  color: #9ca3af;
  font-size: 0.875rem;
`;

const CardContent = styled.p`
  color: #e5e7eb;
  margin-bottom: 1rem;
  line-height: 1.6;
`;

const UrakkaLink = styled.a`
  color: #60a5fa;
  display: block;
  text-align: center;
  margin-top: 2rem;
  font-weight: 500;
  font-size: 1.25rem;
  &:hover {
    color: #93c5fd;
    text-decoration: underline;
  }
`;

const testimonials = [
  {
    location: 'Espoo',
    title: 'Lasiseinä saunaan',
    date: '15.12.2024',
    content:
      'Omakotitaloprojektissa monenlaista tekijää, ja tätä voi kyllä lämpimästi suositella - kaikki toimi sujuvasti, ja jälki erinomaista.',
    rating: 5,
  },
  {
    location: 'Helsinki',
    title: 'Rikkoutuneen parvekelasin vaihto',
    date: '17.11.2024',
    content: 'Hyvä lopputulos ja ystävällistä palvelua, suosittelen!',
    rating: 5,
  },
  {
    location: 'Vantaa',
    title: 'Keittiön välitilalevyn vaihto',
    date: '15.07.2024',
    content:
      'Urakka toteutui kaikin puolin sovitusti ja yhteydenpito oli asiallista!',
    rating: 5,
  },
  {
    location: 'Sipoo',
    title: 'Saunan ja kylpyhuoneen välisen lasiseinän asennus',
    date: '04.04.2024',
    content:
      'Lasitöiden ammattilaiset, mukava ja nopea palvelu, erinomainen työnjälki',
    rating: 5,
  },
  {
    location: 'Helsinki',
    title: 'Peililasisen liukuoven korjaus',
    date: '04.04.2024',
    content:
      'Erinomainen palvelu ja asiansa osaava ammattilainen. Selkeä tarjous, joka piti loppuun asti paikkansa. Aikataulut juuri niin kuin sovittiin, ei mitään viivästyksiä. Itse ovesta tuli kuin uusi. Suosittelen ehdottomasti.',
    rating: 5,
  },
  {
    location: 'Espoo',
    title: 'Peililiukuoven lasin uusiminen',
    date: '08.03.2024',
    content:
      'Nopea toimitus ja hyvä hinta-laatusuhde. Suosittelen ehdottomasti.',
    rating: 5,
  },
  {
    location: 'Espoo',
    title:
      'Liukuovikiskon hiekkapuhallus ja maalaus. Mitat: 150cm x 6cm x 1cm.',
    date: '05.03.2024',
    content: 'Erittäin nopea ja avulias palvelu. Suosittelen.',
    rating: 5,
  },
  {
    location: 'Espoo',
    title: 'Keittiön välitilan lasin uusinta',
    date: '15.01.2024',
    content:
      'Remontista saatiin sovittua nopeasti suomenkielellä ja remonttiaikakin järjestyi myös nopeasti. Sähkötöitä varten kannattaa varata erikseen sähköhenkilö.',
    rating: 5,
  },
  {
    location: 'Helsinki',
    title: 'Taloyhtiöurakka: Lasi-ikkunan korjaus',
    date: '11.11.2023',
    content:
      'Kysymyksesä erikoisikkuna, kellarin lähes vaakarasossa oleva vahvisrettua lasia oleva ikkuna. Toimitusaika piti, siististi asennettu ja hinta kohdallaan, Voin suositella.',
    rating: 5,
  },
  {
    location: 'Espoo',
    title: 'Ikkunan vaihto',
    date: '19.09.2023',
    content:
      'Palvelu oli erinomaista! Kävivät mökillä omatoimisesti tyyliin seuraavana päivänä kun olin hyväksynyt tarjouksen. Siitä taas meni päivä tai kaksi niin olivat käyneet vaihtamassa lasin. Asiakkaan näkökulmasta ei olisi voinut olla parempaa palvelua.',
    rating: 5,
  },
  {
    location: 'Nurmijärvi',
    title: 'Saunan lasiseinän asennus',
    date: '25.08.2023',
    content:
      'Loistavaa palvelua! Tarjous, mittaus sekä asennus nopeassa aikataulussa erittäin asiantuntevasti. Iso suositus!',
    rating: 5,
  },
  {
    location: 'Siuntio',
    title: 'Lämpölasin vaihto',
    date: '06.07.2023',
    content: 'Nopea, täsmällinen ja asiantunteva palvelu,',
    rating: 5,
  },
  {
    location: 'Vantaa',
    title: 'Portaiden puukaiteiden vaihto lasisiin.',
    date: '02.06.2023',
    content: 'Suosittelen!',
    rating: 5,
  },
];

const TestimonialCardComponent = ({ testimonial }) => (
  <div className="col-md-6 col-lg-4">
    <TestimonialCard>
      <CardHeader>
        <div>
          <CardTitle>{testimonial.title}</CardTitle>
          <StarRating>{'★'.repeat(testimonial.rating)}</StarRating>
          <CardLocation>{testimonial.location}</CardLocation>
        </div>
        <CardDate>{testimonial.date}</CardDate>
      </CardHeader>
      <CardContent>{testimonial.content}</CardContent>
    </TestimonialCard>
  </div>
);

const Testimonials = () => {
  return (
    <TestimonialSection>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <SectionTitle>Mitä asiakkaamme sanovat</SectionTitle>
            <SectionSubtitle>
              Luotettavaa ja ammattitaitoista palvelua vuodesta toiseen
            </SectionSubtitle>
          </div>
        </div>
        <div className="row">
          {testimonials.map((testimonial, index) => (
            <TestimonialCardComponent key={index} testimonial={testimonial} />
          ))}
        </div>
        <UrakkaLink
          href="https://www.urakkamaailma.fi/urakoitsija/lasiliukkonen-oy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Katso kaikki arvostelumme Urakkamaailmasta
        </UrakkaLink>
      </div>
    </TestimonialSection>
  );
};

export default Testimonials;
